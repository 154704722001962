import React from "react"
import arrow from "../images/navbar/chevron_menu.svg"

const MyDropdown = ({ selectedDropdown, name, children, location, click }) => {
  return (
    <div
      className={`my-dropdown${selectedDropdown === name ? " selected" : ""}`}
    >
      <button
        onClick={() => click(name)}
        className={
          children.map(link => link.link).includes(location.pathname)
            ? "active"
            : null
        }
      >
        <span>{name}</span>
        <img src={arrow} alt="arrow" />
      </button>
      <div className="my-dropdown-content">
        {children.map(link => (
          <a
            key={link.name}
            href={link.link}
            className={location.pathname === link.link ? "active" : null}
          >
            {link.name}
          </a>
        ))}
      </div>
    </div>
  )
}

export default MyDropdown
