import React, { useMemo, useState } from "react"
import Header from "./header"
import Footer from "./Footer"
import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/main.css"
import "../styles/blocks.css"
// import "../styles/blocks_backup.css"
import "../styles/print.css"
import { Alert } from "react-bootstrap"
import Cookies from "universal-cookie"

const Layout = ({ children, location }) => {
  const cookies = new Cookies()
  const [show, setShow] = useState(cookies.get("consent") ? false : true)

  const handleClose = () => {
    cookies.set("consent", false, { path: "/" })
    setShow(false)
  }

  const lang = useMemo(() => {
    return location.pathname.slice(0, 3) === "/en" ? "en" : "fr"
  }, [location])

  const modalMessage = useMemo(() => {
    return lang === "fr" ? (
      <p className="purple">
        En poursuivant votre navigation sur notre site, vous acceptez
        l’utilisation de Cookies pour nous permettre d'établir des statistiques
        de visites et ainsi améliorer notre site selon votre utilisation.
      </p>
    ) : (
      <p className="purple">
        By continuing to browse our site, you accept the use of cookies to
        enable us to establish visit statistics and thus improve our site
        according to your use.
      </p>
    )
  }, [lang])

  return (
    <>
      <Header lang={lang} location={location} />
      <div className="main">
        <main>{children}</main>
        <Alert
          show={show}
          onClose={handleClose}
          dismissible
          variant="light"
          className="custom-alert fixed-bottom"
        >
          <div className="container">
            {modalMessage}
            <div className="d-flex align-items-center justify-content-end">
              <a href={`/${lang}/mentions_legales`}>
                {lang === "fr" ? "En savoir plus" : "To know more about it"}
              </a>
              <button
                type="button"
                className="btn btn-light"
                onClick={handleClose}
              >
                OK
              </button>
            </div>
          </div>
        </Alert>
      </div>
      <Footer lang={lang} />
    </>
  )
}

export default Layout
