import React from "react"

const MyLink = ({ link, name, location }) => {
  return (
    <a href={link} className={location.pathname === name ? "active" : null}>
      {name}
    </a>
  )
}

export default MyLink
