import React, { useMemo } from "react"

import mblLogo from "../images/footer/MLB-Logotype-white.svg"
import certifiedLogo from "../images/footer/Certified_B_Corporation_B_Corp_Logo_2022_white_RGB.svg"

import facebook from "../images/footer/facebook.svg"
import instagram from "../images/footer/instagram.svg"
import linkedin from "../images/footer/linkedin.svg"
import twitter from "../images/footer/twitter.svg"

export default function Footer({ lang }) {
  const alcoholMessage = useMemo(() => {
    return lang === "fr" ? (
      <div className="alcohol-prevention">
        <span>
          L'ABUS D'ALCOOL EST DANGEREUX POUR LA SANTÉ. À CONSOMMER AVEC
          MODÉRATION.
        </span>
        <span>
          L'ALCOOL NE DOIT PAS ÊTRE CONSOMMÉ DURANT LA GROSSESSE. IL EST
          INTERDIT DE VENDRE DE L'ALCOOL AUX MINEURS.
        </span>
      </div>
    ) : (
      <div className="alcohol-prevention">
        <span>
          ALCOHOL ABUSE IS DANGEROUS FOR HEALTH. TO CONSUME WITH MODERATION.
        </span>
        <span>
          ALCOHOL SHOULD NOT BE CONSUMED DURING PREGNANCY. IT IS FORBIDDEN TO
          SELL ALCOHOL TO MINORS.
        </span>
      </div>
    )
  }, [lang])

  return (
    <footer>
      <div className="footer-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 text-center">
              <img src={mblLogo} alt="instagram logo" style={{maxWidth: 300+'px'}}/>
            </div>
            <div className="col-lg-2 col-md-12 text-center">
              <img src={certifiedLogo} alt="instagram logo" style={{maxWidth: 150+'px'}}/>
            </div>
            <div className="col-lg-2 col-md-12 text-center">
              <span className="footer-container-title">
              {lang === "fr" ? 
              "Cette entreprise respecte des normes sociales et environnementales élevées.":
              "This company respects high social and environmental standards."}
              </span>
              <br/>
              <hr style={{border: 4+'px solid white'}}></hr>
            </div>
            <div className="col-lg-2 col-md-12">
            <span className="footer-container-title">
                {lang === "fr" ? "NOUS CONTACTER" : "CONTACT US"}
              </span>
              <div className="footer-container-item-container">
                <span>Maison Le Breton</span>
                <span>Domaine de la Jasse</span>
                <span>F-34980 Combaillaux</span>
              </div>
              <div className="footer-container-item-container">
                <span>+33 4 67 67 04 04</span>
                <a href="mailto:info@maison-le-breton.com">
                  <span className="white">info@maison-le-breton.com</span>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-12">
              <span className="footer-container-title">
                {lang === "fr" ? "NOS MARQUES" : "OUR BRANDS"}
              </span>
              <div className="footer-container-item-container">
                <a href="https://www.delajasse.com/" target="_blank" rel="noopener noreferrer">
                  <span className="white">La Jasse</span>
                </a>
                <a href="https://www.montlobre.com/" target="_blank" rel="noopener noreferrer">
                  <span className="white">Montlobre</span>
                </a>
                <a href="https://www.2soleils.com/" target="_blank" rel="noopener noreferrer">
                  <span className="white">Vignes Des Deux Soleils</span>
                </a>
              </div>

              <span className="footer-container-title">
                {lang === "fr" ? "NAVIGUER" : "NAVIGATE"}
              </span>
              
              <div className="footer-container-item-container">
                <a href={lang === 'fr' ? '/fr/reseaux-contribution-soutien' : '/fr/reseaux-contribution-soutien'}>
                <span className="white">
                    {lang === "fr" ? "Nos réseaux" : "Our network"}
                  </span>
                </a>
                <a href={lang === 'fr' ? '/fr/labels-certifications' : '/fr/labels-certifications'}>
                  <span className="white">
                    {lang === "fr" ? "Nos certifications" : "Our certification"}
                  </span>
                </a>
                <a href={lang === 'fr' ? '/fr/telechargements' : '/en/downloads'}>
                  <span className="white">
                    {lang === "fr" ? "Nos politiques" : "Our politics"}
                  </span>
                </a> 
                <a href={lang === "fr" ? "/fr/equipe-et-recrutement" : "/en/team-and-careers"}>
                  <span className="white">
                    {lang === "fr" ? "Recrutement" : "Recruitment"}
                  </span>
                </a>
              </div>
            </div>

            <div className="col-lg-2 col-md-12">
              <span className="footer-container-title">
                {lang === "fr" ? "SUIVEZ-NOUS" : "FOLLOW US"}
              </span>
              <div className="footer-container-item-container-logo">
                <a
                  href="https://www.facebook.com/blbvignobles/"
                  className="footer-container-item-container-logo-img"
                >
                  <img src={facebook} alt="facebook logo" />
                </a>
                <span>facebook</span>
              </div>
              <div className="footer-container-item-container-logo">
                <a
                  href="https://twitter.com/maison_lb"
                  className="footer-container-item-container-logo-img"
                >
                  <img src={twitter} alt="twitter logo" />
                </a>
                <span>twitter</span>
              </div>
              <div className="footer-container-item-container-logo">
                <a
                  href="https://www.linkedin.com/company/blbvignobles"
                  className="footer-container-item-container-logo-img"
                >
                  <img src={linkedin} alt="linkedin logo" />
                </a>
                <span>linkedin</span>
              </div>
              <div className="footer-container-item-container-logo">
                <a
                  href="https://www.instagram.com/maison_le_breton/"
                  className="footer-container-item-container-logo-img"
                >
                  <img src={instagram} alt="instagram logo" />
                </a>
                <span>instagram</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {alcoholMessage}
      <div className="copyright">
        <span>
          &copy; 2023 - <b>Maison Le Breton</b> -{" "}
          <a
            href="https://comonlight.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {lang === "fr" ? "Réalisation" : "Realization"} <b>ComOnLight</b>
          </a>{" "}
          -{" "}
          <a href={lang === "fr" ? `/fr/mentions-legales` : `/en/legal-notice`}>
            {lang === "fr" ? "MENTIONS LÉGALES" : "LEGAL NOTICE"}
          </a>
        </span>
      </div>
    </footer>
  )
}
