import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useEffect, useMemo } from "react"
import blb from "../images/navbar/MLB-Logotype.svg"
import bigBlb from "../images/navbar/MLB-Logotype.svg"
import facebook from "../images/navbar/facebook.svg"
import instagram from "../images/navbar/instagram.svg"
import linkedin from "../images/navbar/linkedin.svg"
import twitter from "../images/navbar/twitter.svg"
import blblogomobile from "../images/navbar/MLB-Logotype-white.svg"
import MyDropdown from "./MyDropdown"
import MyLink from "./MyLink"
import certified from "../images/navbar/Certified_B_Corporation_B_Corp_Logo_2022_Black_RGB.svg"


const Header = ({ lang, location }) => {
  const links = useMemo(() => {
    return [
      {
        name: "MAISON LE BRETON",
        link: null,
        links: [
          {
            name: lang === "fr" ? "VISION DE BRUNO LE BRETON" : "BRUNO LE BRETON'S VISION",
            link: lang === 'fr' ? '/fr/la-vision-de-bruno-le-breton' : '/en/bruno-le-breton-vision',
          },
          {
            name: lang === "fr" ? "VALEURS" : "VALUES",
            link: lang === 'fr' ? '/fr/valeurs-et-missions' : '/en/values-and-missions',
          },
          {
            name: lang === "fr" ? "HISTOIRE & CHIFFRES CLÉS" : "HISTORY & KEY FIGURES",
            link: lang === 'fr' ? '/fr/histoire-de-blb-vignobles' : '/en/histoiry-of-blb-vignobles',
          },
          {
            name: lang === "fr" ? "ÉQUIPE & RECRUTEMENT" : "TEAM AND CAREERS",
            link: lang === 'fr' ? '/fr/equipe-et-recrutement' : '/en/team-and-careers',
          }
        ],
      },
      {
        name: "MISSION",
        link: null,
        links: [
          {
            name: lang === "fr" ? "SOCIÉTÉ À MISSION" : "PURPOSE AND MISSION",
            link: lang === 'fr' ? '/fr/societe-a-mission' : '/en/purpose-mission',
          },
          {
            name: lang === "fr" ? "LE BON, QUALITÉ GUSTATIVE" : "THE GOOD, TASTING QUALITY",
            link: lang === 'fr' ? '/fr/le-bon-qualite-gustative' : '/en/the-good-taste-quality',
          },
          {
            name: lang === "fr" ? "LE BIEN, QUALITÉ SOCIALE" : "THE RIGHT, SOCIAL QUALITY",
            link: lang === 'fr' ? '/fr/le-bien-qualite-sociale' : '/en/the-right-social-quality',
          },
          {
            name: lang === "fr" ? "LE BIEN, QUALITÉ ENVIRONNEMENTALE" : "THE RIGHT, ENVIRONMENTAL QUALITY",
            link: lang === 'fr' ? '/fr/le-bien-qualite-environnementale' : '/en/the-right-environmental-quality',
          },
          {
            name: lang === "fr" ? "LE BEAU, QUALITÉ ÉMOTIONNELLE" : "THE BEAUTIFUL, EMOTIONAL QUALITY",
            link: lang === 'fr' ? '/fr/le-beau-qualite-emotionnelle' : '/en/the-beautiful-emotional-quality',
          }
        ],
      },
      {
        name: lang === "fr" ? "NOS VINS" : "OUR WINES",
        link: null,
        links: [
          {
            name: lang === "fr" ? "LA JASSE" : "LA JASSE",
            link: lang === 'fr' ? '/fr/domaine-de-la-jasse' : '/en/la-jasse-wines',
          },
          {
            name: lang === "fr" ? "MONTLOBRE" : "MONTLOBRE",
            link: lang === 'fr' ? '/fr/domaine-montlobre' : '/en/montlobre-wines',
          },
          {
            name: lang === "fr" ? "VIGNES DES DEUX SOLEILS" : "VIGNES DES DEUX SOLEILS",
            link: lang === 'fr' ? '/fr/vignes-des-deux-soleils' : '/en/vignes-des-deux-soleils-wines',
          },
          {
            name: lang === "fr" ? "OÙ TROUVER NOS VINS" : "WHERE TO FIND OUR WINES",
            link: lang === 'fr' ? '/fr/ou-trouver-nos-vins' : '/en/finding-our-wines',
          },
        ],
      },
      {
        name: lang === "fr" ? "TÉLÉCHARGEMENTS" : "DOWNLOADS",
        link: lang === 'fr' ? '/fr/telechargements' : '/en/downloads',
        links: [],
      },
      {
        name: lang === "fr" ? "CONTACT" : "CONTACT",
        link: lang === 'fr' ? '/fr/contact' : '/en/contact-us',
        links: [],
      },
    ]
  }, [lang])

  const [showMenu, setShowMenu] = useState(false)
  const [scrollTop, setScrollTop] = useState(true)
  const [hasRan, setHasRan] = useState(false)
  const [screenSize, setScreenSize] = useState({
    height: 0,
    width: 0,
  })
  const [selectedDropdown, setSelectedDropdown] = useState(null)

  const changeShowMenu = () => {
    setShowMenu(!showMenu)
  }

  useEffect(() => {
    const onScroll = e => {
      if ((e.target.documentElement.scrollTop === 0) !== scrollTop) {
        setScrollTop(!scrollTop)
      }
    }
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  const updateScreenSize = () => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight })
  }

  useEffect(() => {
    if (!hasRan) {
      setHasRan(true)
      updateScreenSize()
    }
    window.addEventListener("resize", updateScreenSize)
    return () => {
      window.removeEventListener("resize", updateScreenSize)
    }
  }, [screenSize, hasRan])

  const handleClickDropdown = buttonName => {
    setSelectedDropdown(buttonName === selectedDropdown ? null : buttonName)
  }

  return (
    <header className={showMenu ? "show" : null}>
      <div className={`my-navbar${scrollTop ? " big" : ""}`}>
        <div className="mobile-navbar">
          <a href={`/${lang}/`}>
            <img
              className="mobile-logo"
              src={blblogomobile}
              alt="logo blb vignobles"
            />
          </a>
          <div className="language-buttons">
            <a href="/fr/" className={lang === "fr" ? "active" : null}>
              <span>FR</span>
            </a>
            &nbsp;-&nbsp;
            <a href="/en/" className={lang === "en" ? "active" : null}>
              <span>EN</span>
            </a>
          </div>
          <button onClick={changeShowMenu}>
            {showMenu ? (
              <FontAwesomeIcon icon={faTimes} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </button>
        </div>
        <div className="my-navbar-container">
          {links.map(link =>
            link.links.length > 0 ? (
              <MyDropdown
                key={link.name}
                name={link.name}
                children={link.links}
                selectedDropdown={selectedDropdown}
                location={location}
                click={handleClickDropdown}
              />
            ) : (
              <MyLink
                key={link.name}
                link={link.link}
                name={link.name}
                location={location}
              />
            )
          )}
        </div>
      </div>
      <div
        className={`left-navbar${
          scrollTop && screenSize.width > 1500 ? " big" : ""
        }`}
      >
        <a href={`/${lang}/`}>
          <img
            src={scrollTop && screenSize.width > 1500 ? bigBlb : blb}
            className="blb-logo"
            alt="logo BLB vignobles"
          />
        </a>
        <div className="language-buttons">
          <a href="/fr/" className={lang === "fr" ? "active" : null}>
            <span>FR</span>
          </a>
          <a href="/en/" className={lang === "en" ? "active" : null}>
            <span>EN</span>
          </a>
        </div>
        <div className="social-network-div">
        <a
          className="social-network"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/blbvignobles/"
        >
          <img src={facebook} alt="facebook icon" />
        </a>
        <a
          className="social-network"
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/maison_lb"
        >
          <img src={twitter} alt="twitter icon" />
        </a>
        <a
          className="social-network"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/blbvignobles"
        >
          <img src={linkedin} alt="linkedin icon" />
        </a>
        <a
          className="social-network"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/maison_le_breton/"
        >
          <img src={instagram} alt="instagram icon" />
        </a>
        </div>

        <a
            className="btn btn-site"
            style={{display: (lang === "fr" ? "inherit" : "none") }}
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.boutiquedelajasse-montlobre.com/"
        >
          Boutique
        </a>


        <a
        href={lang === "fr" ? "/fr/societe-a-mission" : "/en/purpose-mission"}
          className="certified-link">
          <img src={certified} alt="Mission" />
        </a>
      </div>
    </header>
  )
}

export default Header
